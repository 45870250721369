<template>
  <div class="container-table">
    <!-- Table Body -->
    <template v-if="!loading && purchases.length > 0">
      <b-row class="mb-4">
        <b-col
          v-for="purchase in purchases"
          :key="purchase.id"
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="4"
        >
          <div class="card_purchase my-2 p-3">
            <div class="mb-2 d-flex align-items-center">
              <div class="mr-2">
                <img
                  class="rounded"
                  :src="purchase.image"
                  height="70"
                  width="70"
                  alt="app"
                />
              </div>
              <div class="my-2">
                <p class="text1" :class="updateStatus(purchase.status).class">
                  {{ updateStatus(purchase.status).text || "-" }}
                </p>
                <p class="text1 mb-0">
                  {{ $t("views.buyer.dashboard.text_124") }}
                  {{ moment(purchase.created_at).format("DD MMMM") }}
                </p>
              </div>
            </div>
            <div>
              <p v-if="purchase.warranty" class="text2">
                <span v-if="purchase.paid_at">{{
                  $t("views.buyer.dashboard.text_125")
                }}</span>
                {{
                  formatDateWithBrowserTimeZone(
                    purchase.paid_at,
                    "dddd, DD [de] MMMM [de] YYYY",
                    purchase.warranty_days
                  )
                }}
              </p>
              <p class="text3">{{ purchase.product_name }}</p>
              <p class="text3" :class="updateStatus(purchase.status).class">
                {{ purchase.installments }}x {{ purchase.currency_symbol }}
                {{ calcBalance(purchase.balance, purchase.installments) }}
              </p>
            </div>
            <div class="d-flex justify-content-between">
              <div class="mr-2">
                <p class="text3">{{ purchase.seller_name }}</p>
                <p
                  v-if="purchase.support_telephone"
                  class="text1 text-blue cursor-pointer"
                  @click="openLink(updateTel(purchase.support_telephone))"
                >
                  {{ $t("views.buyer.dashboard.text_126") }}
                </p>
                <p v-else class="text1 text-gray">
                  {{ $t("views.buyer.dashboard.text_126") }}
                </p>
              </div>
              <div>
                <BaseButton
                  variant="info2"
                  :class="{ heaven: isHeaven }"
                  style="height: 40px !important"
                  @click="openPurchasePreview(purchase.id)"
                >
                  {{ $t("views.buyer.dashboard.text_127") }}
                </BaseButton>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </template>

    <!-- Pagination -->
    <b-row>
      <b-col>
        <Paginate
          v-if="purchases.length > 0"
          :totalPages="pagination.totalPages"
          :activePage="pagination.currentPage"
          :items-per-page="pagination.perPage"
          :justify_start="true"
          :isPurchase="true"
          @to-page="toPage"
          @per-page="perPage"
        />
      </b-col>
    </b-row>
    <!-- Loading -->
    <div
      v-if="(loading && !purchases.length) || loading"
      class="Table-body py-4 d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="dark" label="Spinning"></b-spinner>
    </div>
    <!-- Feedback Message -->
    <b-row
      v-if="!loading && !purchases.length"
      class="Table-body justify-content-center"
    >
      <p class="nao-encontrado">{{ $t("views.buyer.dashboard.text_129") }}</p>
    </b-row>

    <!-- Modals -->
    <PurchasePreview
      ref="purchaseSubscriptionPreview"
      :updateQueryParams="setQueryParams"
    />
  </div>
</template>

<script>
import Paginate from "@/components/shared/Paginate";
import PurchasePreview from "@/components/Buyer/purchases/PurchasePreview";
import Money from "@/mixins/money";
import { iconMethod } from "@/helpers.js";
import { EventBus } from "@/main.js";
import moment from "@/plugins/moment";
import formatTel from '@/utils/formatTel.js'
import getStatus from '@/utils/getStatus.js'

import BuyerService from "@/services/resources/BuyerService";

const service = BuyerService.build();

export default {
  name: "MyPurchasesSubscriptionTable",
  components: { Paginate, PurchasePreview },
  mixins: [Money],
  props: {
    tabIndex: {
      required: true,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      purchases: [],
      purchase_id: -1,
      loading: true,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 6,
      },
      moment: moment,
    };
  },
  computed: {
    isHeaven() {
      return this.$store.getters["getIsHeaven"];
    },
  },
  methods: {
    formatDateWithBrowserTimeZone(date, format, addDays = null) {
      if (!date) {
        return this.$t("views.buyer.my_purchases.text_3051");
      }
      const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const momentObj = moment.tz(date, browserTimeZone);

      // Adiciona dias apenas se addDays for fornecido
      if (addDays !== null) {
        momentObj.add(addDays, "days");
      }

      return momentObj.format(format);
    },
    calcBalance(value, installment) {
      const totalValue = this.formatMoney(value);
      const valueValidated = value ? parseFloat(totalValue) : 0;
      const installmentValidated = installment ? parseInt(installment) : 1;
      const res = this.formatMoney(valueValidated / installmentValidated);
      return res;
    },
    getIconMethod(method) {
      return iconMethod(method);
    },
    getMethod(method) {
      return this.textMethod(method);
    },
    updateTel(tel) {
      return formatTel(tel);
    },
    updateStatus(status) {
      return getStatus(status)
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchPurchases();
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchPurchases();
    },
    fetchPurchases() {
      this.loading = true;
      this.pagination.totalPages = 1;
      let data = {
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        type: "CONTRACT",
      };

      service
        .search(data)
        .then((response) => {
          this.purchases = response.data;
          this.pagination.totalPages = response.meta.last_page;
          this.$emit("update-total", response.meta.total);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false;
        });
    },
    setQueryParams(isNotToRefresh) {
      if (isNotToRefresh) {
        this.$router.replace({ query: {} });
      } else {
        this.fetchPurchases();
      }
    },
    textMethod(method) {
      if (method) {
        switch (method.toUpperCase()) {
          case "CREDIT_CARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "CREDIT-CARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "BANKTRANSFER":
            return this.$t("views.seller.edit_product.text_1113");
          case "CREDITCARD":
            return this.$t("views.seller.edit_product.text_1112");
          case "DEBITCARD":
            return this.$t("views.seller.edit_product.text_1114");
          case "debitcard":
            return this.$t("views.seller.edit_product.text_1114");
          case "TWO_CREDIT_CARDS":
            return this.$t("views.seller.edit_product.text_1115");
          case "DEBITCARD":
            return "CARTÃO DE DÉBITO";
          case "EFT":
            return "EFT";
          case "SPEI":
            return "SPEI";
          case "SENCILLITO":
            return "SENCILLITO";
          case "OXXO":
            return "OXXO";
          default:
            return method;
        }
      } else return method;
    },
    openPurchasePreview(purchase_id) {
      this.purchase_id = purchase_id;
      this.$router.push({
        query: { pur_id: this.purchase_id, tab: "CONTRACT" },
      });

      setTimeout(() => {
        this.$refs.purchaseSubscriptionPreview.openPanel(this.purchase_id);
        this.loading = false;
      });
    },
    openLink(link) {
      window.open(link, "_blank");
    },
  },
  mounted() {
    this.fetchPurchases();

    if (typeof this.$route.query.pur_id != "undefined") {
      this.loading = true;
      this.purchase_id = this.$route.query.pur_id;
      setTimeout(() => {
        this.$refs.purchaseSubscriptionPreview.openPanel(this.purchase_id);
        this.loading = false;
      }, 1000);
    }
    EventBus.$on("get-purchases", () => {
      this.fetchPurchases();
    });
  },
};
</script>

<style scoped>
.card_purchase {
  background-color: var(--gray-10);
  border-radius: 5px;
}
.text1 {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: var(--old-800);
}
.text2 {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: var(--old-800);
}
.text3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: var(--gray-200);
}
.text-blue {
  color: var(--blue-50);
}
.text-gray {
  color: var(--gray-200);
}
.see_more:hover {
  transform: scale(1.1);
}
</style>
